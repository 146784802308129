<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
      <!-- Content -->
      <v-col class="col-sm-7 ma-0 mt-5 mt-sm-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
          <v-col align="center" class="col-sm-6 ma-0 pa-0">
            <v-card class="pa-5 w-100" fluid>
              <!-- Verify Email -->
              <div v-if="$route.query.mode==='verifyEmail'">
              </div>
              <!-- Reset / Change Password -->
              <div v-else-if="$route.query.mode==='resetPassword'">
                <!-- Reset Password -->
                <v-form v-if="!$route.query.oobCode"
                  @submit.prevent="submitResetPassword"
                  ref="resetPasswordForm"
                  v-model="validResetPassword"
                >
                  <h2>Reset Your Password</h2>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    ref="emailField"
                    label="Email Address"
                    :disabled="isPasswordResetEmailSent"
                    required
                    @keydown.enter="submitResetPassword"
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    class="blue darken-3 mt-3 white--text"
                    :loading="loading"
                    :disabled="isPasswordResetEmailSent"
                  >
                    Submit
                  </v-btn>
                </v-form>
                <!-- Change Password -->
                <v-form v-else-if="isPasswordResetCodeValid"
                  @submit.prevent="submitChangePassword"
                  ref="changePasswordForm"
                  v-model="validChangePassword"
                >
                  <h2>Create a New Password</h2>
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :type="'password'"
                    label="New Password"
                    required
                    @keydown.enter="submitChangePassword"
                  ></v-text-field>
                  <v-text-field
                    v-model="password2"
                    :rules="passwordRules"
                    :type="'password'"
                    label="Confirm Password"
                    required
                    @keydown.enter="submitChangePassword"
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    class="blue darken-3 mt-3 white--text"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </v-form>
                <!-- Complete -->
                <v-col v-else-if="isPasswordChanged" align="center">
                  <v-row justify="center">Your password has been changed.</v-row>
                  <v-row justify="center"><v-btn color="blue darken-3 mt-5 white--text" to="/login">Log In</v-btn></v-row>
                </v-col>
              </div>
              <!-- Change Email -->
              <div v-else-if="$route.query.mode==='recoverEmail'">
              </div>
              <!-- Error -->
              <div class="error--text mt-3">{{ error }}</div>
            </v-card>
          </v-col>
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AuthManagement',

    data () {
      return {
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        error: '',
        isPasswordResetCodeValid: false,
        isPasswordResetEmailSent: false,
        isPasswordChanged: false,
        loading: false,
        password: '',
        password2: '',
        validChangePassword: false,
        validResetPassword: false
      }
    },

    computed: {
      passwordRules() {
        const { password, password2 } = this
        return [
          v => !!v || 'Password is required.',
          v => v.length >= 8 || 'Passwords must contain at least 8 characters.',
          password == password2 || 'Passwords must match.'
        ]
      }
    },

    mounted () {
      if (this.$route.query.mode === 'resetPassword' && this.$route.query.oobCode) {
        const code = this.$route.query.oobCode
        this.loading = true
        this.$store.dispatch('verifyPasswordResetCode', { code })
        .then((resp) => {
          if (resp.code) {
            this.loading = false
            this.error = resp.message
          } else if (resp.indexOf("@") > -1) { // if it returns an email address, success
            this.loading = false
            this.isPasswordResetCodeValid = true
          } else {
            this.loading = false
            this.error = resp
            console.log(resp)
          }
        })
      } else if (this.$route.query.mode === 'verifyEmail') {
        this.$router.push('/login')
      }
    },

    methods: {
      submitChangePassword () {
        this.error = ''
        this.$refs.changePasswordForm.validate()
        if (this.validChangePassword && !this.loading) {
          this.loading = true
          const code = this.$route.query.oobCode
          const { password } = this
          this.$store.dispatch('confirmPasswordReset', { code, password })
          .then(() => {
            this.loading = false
            this.isPasswordResetCodeValid = false
            this.isPasswordChanged = true
            this.password = ''
            this.password2 = ''
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      },

      submitResetPassword () {
        this.error = ''
        this.$refs.resetPasswordForm.validate()
        if (this.validResetPassword && !this.loading) {
          this.loading = true
          const { email } = this
          this.$store.dispatch('resetPassword', { email })
          .then(() => {
            this.loading = false
            this.isPasswordResetEmailSent = true
            this.error = "We sent you an email to reset your password. Please follow the instructions in the email to continue."
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
